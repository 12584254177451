export const TOP_LIST_COLORS = ['#ffd700', '#c0c0c0', '#cd7f32'];

export const SECOND = 1000;

export const YEAR_START = 2022;

export const COVER_MAX_FILE_SIZE = 100 * 1024;

export const COVER_MAX_DIMENSIONS = 360;

export const RATINGS = {
  5: "Świetna gra, muszę ją mieć",
  4: "Bardzo ciekawa, wyróżnia się, chętnie zagram",
  3: "Wygląda OK, nie obrażę się jak wpadnie do kolekcji",
  2: "Raczej słaba, rzadko będzie trafiać na stół",
  1: "Absolutnie nie chcę tej gry, nie podoba mi się"
}