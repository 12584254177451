import {useEffect, useMemo, useState} from 'react';
import {InputText} from "primereact/inputtext";
import {InputNumber} from "primereact/inputnumber";
import {Button} from "primereact/button";
import {Chips} from "primereact/chips";
import {Divider} from "primereact/divider";
import CoverUploader from "../../common/CoverUploader";
import Select from "react-select";
import {BOX_SIZE_OPTIONS, GAME_SOURCE_OPTIONS} from "../../common/Enums";
import {dateToString, isSmallScreenVertical} from "../../utilities/CommonUtils";
import {GameService} from "../../service/GameService";
import {Checkbox} from "primereact/checkbox";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";

export default function GameForm(props) {
  const emptyGame = useMemo(() => ({
    name: '',
    minPlayers: 0,
    maxPlayers: 0,
    minMinutes: 0,
    maxMinutes: 0,
    expansions: [],
    gameSource: null,
    ownedSince: dateToString(new Date()),
    dateSold: null,
    hasCoopMode: false,
    hasCampaignMode: false,
    boxSize: null,
    costs: []
  }), []);
  const [game, setGame] = useState(emptyGame);
  const [initialGameName, setInitialGameName] = useState('');

  useEffect(() => {
    if (props.id) {
      GameService.get(props.id).then(game => {
        recalculateCostIndexes(game);
        setGame(game);
        setInitialGameName(game.name);
      });
    } else {
      setGame(emptyGame);
    }
  }, [props, emptyGame]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    game.costs = game.costs.filter(cost => cost.name !== '');
    GameService.save(game).then(response => {
      if (response.ok) {
        props.onSuccessfulSubmit();
      } else {
        response.text().then(error => props.showFailureToast('Gra nie została zapisana. ' + error));
      }
    });
  }

  const handleChange = (event) => {
    const target = event.target;
    let {value, name} = target;
    const _game = {...game};
    _game[name] = value;
    setGame(_game);
  }

  const handleGameSourceChange = (item) => {
    const _game = {...game};
    _game.gameSource = item ? item.value : '';
    setGame(_game);
  }

  const handleBoxSizeChange = (item) => {
    const _game = {...game};
    _game.boxSize = item ? item.value : '';
    setGame(_game);
  }

  const handleCoopChange = (event) => {
    const _game = {...game};
    _game.hasCoopMode = event.checked;
    setGame(_game);
  }

  const handleCampaignChange = (event) => {
    const _game = {...game};
    _game.hasCampaignMode = event.checked;
    setGame(_game);
  }

  const handleReset = () => {
    setGame({...emptyGame});
    props.onClose();
  }

  const textEditor = options => <InputText value={options.value} onChange={event => options.editorCallback(event.target.value)}/>;

  const numberEditor = options => <InputNumber value={options.value} onValueChange={event => options.editorCallback(event.value)}/>;

  const onCostRowEditComplete = event => {
    const _game = {...game};
    let {newData, index} = event;
    _game.costs[index] = newData;
    setGame(_game);
  };

  const validateCostRow = rowData => {
    const _newCosts = [...game.costs.map(cost => cost.name)];
    const _duplicates = _newCosts.filter(name => _newCosts.indexOf(name) !== rowData.index && name === rowData.name);
    if (_duplicates.length > 0) {
      alert("Wpis o nazwie '" + rowData.name + "' jest już na liście.");
      return false;
    }
    return true;
  };

  const addCostRow = () => {
    const _game = {...game};
    _game.costs.push({index: _game.costs.length, name: '', value: 0});
    setGame(_game);
  };

  const recalculateCostIndexes = game => {
    game.costs = game.costs.map((cost, index) => {
      cost.index = index;
      return cost;
    });
  }

  return (
    <div>
      <h3 className="text-center">{props.id ? 'Edytuj ' + initialGameName : 'Dodaj grę'}</h3>
      <Divider/>
      <div className="d-grid gap-2 align-items-center" style={{gridTemplateRows: "2.5em minmax(2.5em, auto) repeat(9, 2.5em)"}}>
        <label htmlFor="name" style={{gridColumn: "1"}}>Tytuł</label>
        <InputText id="name" name="name" value={game.name} onChange={handleChange} style={{gridColumn: "span 2"}}/>
        <label htmlFor="expansions" style={{gridColumn: "1"}}>Dodatki</label>
        <Chips id="expansions" name="expansions" separator="," value={game.expansions} onChange={handleChange} style={{gridColumn: "span 2"}}
               pt={{container: {className: "flex-grow-1 overflow-auto"}, input: {style: {width: "100px"}}}}/>
        <label style={{gridColumn: "1"}}>Liczba graczy</label>
        <div className="text-center" style={{gridColumn: isSmallScreenVertical() ? "span 2" : "2"}}>
          <InputNumber id="minPlayers" name="minPlayers" size={3} value={game.minPlayers} onValueChange={handleChange}/>-
          <InputNumber id="maxPlayers" name="maxPlayers" size={3} value={game.maxPlayers} onValueChange={handleChange}/>
        </div>
        <label style={{gridColumn: "1"}}>Czas gry</label>
        <div className="text-center" style={{gridColumn: isSmallScreenVertical() ? "span 2" : "2"}}>
          <InputNumber id="minMinutes" name="minMinutes" size={3} value={game.minMinutes} onValueChange={handleChange}/>-
          <InputNumber id="maxMinutes" name="maxMinutes" size={3} value={game.maxMinutes} onValueChange={handleChange}/>
        </div>
        <label htmlFor="ownedSince" style={{gridColumn: "1"}}>Data pozyskania</label>
        <input type="date" id="ownedSince" name="ownedSince" className="p-inputtext" value={game.ownedSince || ''} onChange={handleChange}
               style={{gridColumn: isSmallScreenVertical() ? "span 2" : "2"}}/>
        <label htmlFor="gameSource" style={{gridColumn: "1"}}>Sposób pozyskania</label>
        <Select id="gameSource" name="gameSource" placeholder="..." options={GAME_SOURCE_OPTIONS} onChange={handleGameSourceChange}
                value={GAME_SOURCE_OPTIONS.find(suggestion => suggestion.value === game.gameSource)}
                className={isSmallScreenVertical() ? "form-second-column-span" : "form-second-column"}
        />
        <label htmlFor="dateSold" style={{gridColumn: "1"}}>Data sprzedaży</label>
        <input type="date" id="dateSold" name="dateSold" className="p-inputtext" value={game.dateSold || ''} onChange={handleChange}
               style={{gridColumn: isSmallScreenVertical() ? "span 2" : "2"}}/>
        <label htmlFor="hasCoopMode" style={{gridColumn: "1"}}>Tryb co-op</label>
        <Checkbox id="hasCoopMode" name="hasCoopMode" checked={game.hasCoopMode} onChange={handleCoopChange}
                  style={{gridColumn: isSmallScreenVertical() ? "span 2" : "2"}}/>
        <label htmlFor="hasCampaignMode" style={{gridColumn: "1"}}>Tryb kampanii</label>
        <Checkbox id="hasCampaignMode" name="hasCampaignMode" checked={game.hasCampaignMode} onChange={handleCampaignChange}
                  style={{gridColumn: isSmallScreenVertical() ? "span 2" : "2"}}/>
        <label htmlFor="boxSize" style={{gridColumn: "1"}}>Rozmiar pudełka</label>
        <Select id="boxSize" name="boxSize" placeholder="..." options={BOX_SIZE_OPTIONS} onChange={handleBoxSizeChange}
                value={BOX_SIZE_OPTIONS.find(suggestion => suggestion.value === game.boxSize)}
                className={isSmallScreenVertical() ? "form-second-column-span" : "form-second-column"}
        />
        <div className="d-grid h-100 text-center justify-content-center"
             style={isSmallScreenVertical() ? {gridRow: "span 2", gridColumn: "span 3"} : {gridColumn: "3", gridRow: "3 / 11"}}>
          <img className="mw-100 m-auto cover-shadow" style={{maxHeight: 9 * 2.5 + "em"}} src={`data:image/jpeg;base64,${game.cover}`} alt="Brak okładki"/>
        </div>
        <div className="text-center" style={{gridColumn: "span 3"}}>
          <label htmlFor="cover" className="me-2">Zmień okładkę</label>
          <CoverUploader game={game} setGame={setGame} showFailureToast={props.showFailureToast}/>
        </div>
        <label htmlFor="costs" style={{gridColumn: "1"}}>Wartość</label>
        <DataTable value={game.costs} editMode="row" onRowEditComplete={onCostRowEditComplete} rowEditValidator={validateCostRow} dataKey="index" size="small"
                   stripedRows={true} emptyMessage="Brak danych" footer={`Łącznie ${game.costs.map(cost => cost.value).reduce((a, b) => a + b, 0)} zł`}
                   style={{gridColumn: "span 2"}}>
          <Column field="name" header="Nazwa" editor={textEditor}/>
          <Column field="value" header="Koszt" editor={numberEditor}/>
          <Column alignHeader="right" align="right" rowEditor={true}/>
          <Column header={<Button size="small" icon="pi pi-plus-circle" onClick={addCostRow}/>} alignHeader="right" align="right"
                  body={cost => <Button className="p-row-editor-init" icon="pi pi-trash" onClick={() => {
                    const _game = {...game};
                    _game.costs = _game.costs.filter(c => c.index !== cost.index);
                    recalculateCostIndexes(_game);
                    setGame(_game);
                  }}/>}/>
        </DataTable>
      </div>
      <Divider/>
      <div className="d-flex gap-2 justify-content-center">
        <Button severity="primary" type="submit" onClick={handleSubmit}>Zapisz</Button>
        <Button severity="secondary" type="reset" onClick={handleReset}>Anuluj</Button>
      </div>
    </div>
  )
}
