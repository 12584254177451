export const updatePageTitle = (title) => {
  document.title = 'Planszówki • ' + title;
}

export const dateToString = (date) => {
  const timezoneOffset = date.getTimezoneOffset();
  date.setUTCMinutes(date.getUTCMinutes() - timezoneOffset);
  return date.toISOString().split('T')[0];
};

export const arrayOfNumbers = (from, to) => {
  let result = [];
  for (let i = from; i <= to; i++) {
    result.push(i);
  }
  return result;
}

export const isSmallScreen = () => {
  return isSmallScreenHorizontal() || isSmallScreenVertical();
}

export const isSmallScreenHorizontal = () => {
  return window.innerHeight < 720;
}

export const isSmallScreenVertical = () => {
  return window.innerWidth < 720;
}

export const universalSort = (value1, value2, order) => {
  let result;
  if (value1 == null && value2 != null)
    result = -1;
  else if (value1 != null && value2 == null)
    result = 1;
  else if (value1 == null && value2 == null)
    result = 0;
  else if (typeof value1 === 'string' && typeof value2 === 'string')
    result = value1.localeCompare(value2);
  else
    result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
  return result * order;
}