import Resizer from "react-image-file-resizer";
import {COVER_MAX_DIMENSIONS, COVER_MAX_FILE_SIZE} from "../utilities/Constants";

export default function CoverUploader(props) {

  const getBase64StringFromDataURL = dataURL => dataURL.replace('data:', '').replace(/^.+,/, '');

  const resizeCover = (file, quality) => new Promise(resolve => Resizer.imageFileResizer(file, 2 * COVER_MAX_DIMENSIONS, COVER_MAX_DIMENSIONS, "JPEG", quality, 0, uri => resolve(uri), "file"));

  const handleCoverChange = async (event) => {
    let file = event.target.files[0];
    let imageQuality = 100;
    while (file.size > COVER_MAX_FILE_SIZE && imageQuality >= 80) {
      file = await resizeCover(file, imageQuality--);
    }
    if (file.size > COVER_MAX_FILE_SIZE) {
      props.showFailureToast('Nie udało się zmniejszyć okładki do rozmiaru 100 KB. Ten obraz nie zostanie zapisany.');
      return;
    }
    const fileReader = new FileReader();
    fileReader.onload = () => {
      const _game = {...props.game};
      _game.cover = getBase64StringFromDataURL(fileReader.result);
      props.setGame(_game);
    };
    fileReader.readAsDataURL(file);
  }

  return <input id="cover" name="cover" type="file" accept="image/jpeg, image/png" className="p-inputtext text-center" onChange={handleCoverChange}/>

}
