import {useEffect, useState} from 'react';
import {Panel} from "primereact/panel";
import {BlockUI} from "primereact/blockui";
import {DataLoadingIndicator} from "../../common/DataLoadingIndicator";
import {isSmallScreenVertical, updatePageTitle} from "../../utilities/CommonUtils";
import {GameService} from "../../service/GameService";
import {BIG_BOX_SCALE, generateGallery, resizeCover, SMALL_BOX_SCALE} from "../../utilities/GalleryUtils";
import {InputNumber} from "primereact/inputnumber";
import {Slider} from "primereact/slider";
import {Button} from "primereact/button";
import {useLocalStorage, useSessionStorage} from "primereact/hooks";
import {COVER_MAX_DIMENSIONS} from "../../utilities/Constants";
import Dexie from 'dexie';

export default function GameGallery() {
  const title = 'Galeria gier';
  const cache = new Dexie("gallery.games");
  cache.version(1).stores({
    games: "name",
    updates: "lastUpdate"
  });
  const [galleryGames, setGalleryGames] = useState([]);
  const [galleryBig, setGalleryBig] = useState([]);
  const [gallerySmall, setGallerySmall] = useState([]);
  const [coversBig, setCoversBig] = useState([]);
  const [coversSmall, setCoversSmall] = useState([]);
  const [playersCountFilter, setPlayersCountFilter] = useSessionStorage(null, "gallery.playersCountFilter");
  const [globalScale, setGlobalScale] = useLocalStorage(100, "gallery.globalScale");

  useEffect(() => {
    updatePageTitle(title);
  }, []);

  const loadCover = async (game) => {
    const img = new Image();
    img.src = `data:image/jpeg;base64,${game.cover}`;
    if (game.cover) {
      await img.decode();
    }
    game.img = img;
    return game;
  };

  useEffect(() => {
    GameService.getLastUpdate().then(lastUpdateData => {
      cache.updates.toCollection().first().then(storedLastUpdate => {
        const oldDate = storedLastUpdate ? new Date(storedLastUpdate.lastUpdate) : undefined;
        const newDate = new Date(lastUpdateData.lastUpdate);
        if (!oldDate || newDate > oldDate) {
          cache.games.clear().then(() => GameService.getGallery()).then(fetchedGames => cache.games.bulkPut(fetchedGames).then(() => setGalleryGames(fetchedGames))).then(() => cache.updates.clear()).then(() => cache.updates.put(lastUpdateData));
        } else {
          cache.games.toArray().then(storedGames => setGalleryGames(storedGames));
        }
      });
    });
  }, []); // eslint-disable-line

  useEffect(() => {
    let data = [...galleryGames];
    if (playersCountFilter) {
      data = data.filter(game => game.minPlayers <= playersCountFilter && game.maxPlayers >= playersCountFilter);
    }
    const coverPromises = []
    data.forEach(game => {
      coverPromises.push(loadCover(game));
    });
    Promise.all(coverPromises).then(data => {
      const _coversBig = [];
      const _coversSmall = [];
      data.forEach(game => {
        const img = game.img;
        let size = game.cover ? {width: img.width, height: img.height} : {width: COVER_MAX_DIMENSIONS, height: COVER_MAX_DIMENSIONS};
        const scale = game.boxSize === "SMALL" ? SMALL_BOX_SCALE : BIG_BOX_SCALE;
        size = resizeCover(size, scale, globalScale / 100.0);
        const cover = <img width={size.width} height={size.height} src={img.src} title={game.name} alt={game.name + " (brak okładki)"} data-id={game.id}/>;
        if (scale === SMALL_BOX_SCALE) {
          _coversSmall.push(cover);
        } else {
          _coversBig.push(cover);
        }
      });
      setCoversSmall(_coversSmall);
      setCoversBig(_coversBig);
    });
  }, [galleryGames, playersCountFilter, globalScale]);

  useEffect(() => {
    setGalleryBig(generateGallery(coversBig));
  }, [coversBig]);

  useEffect(() => {
    setGallerySmall(generateGallery(coversSmall));
  }, [coversSmall]);


  const headerTemplate = () => {
    return <div className={"p-panel-header bg-white" + (isSmallScreenVertical() ? " d-flex flex-column" : "")}>
      <h3>{title}</h3>
      <div className="d-flex align-items-center gap-3">
        <span>Skala</span>
        <span>
          <InputNumber inputClassName="text-center" size={4} inputStyle={{height: "2rem"}} value={globalScale} suffix={"%"} readOnly={true}/>
          <Slider min={50} max={100} step={10} value={globalScale} onChange={event => setGlobalScale(event.value)}/>
        </span>
        <span>Liczba graczy</span>
        <span>
          <InputNumber inputClassName="text-center" size={4} inputStyle={{height: "2rem"}} min={1} max={12}
                       value={playersCountFilter} onChange={event => setPlayersCountFilter(event.value)}/>
          <Slider min={1} max={12} value={playersCountFilter} onChange={event => setPlayersCountFilter(event.value)}/>
        </span>
        <Button size="small" disabled={!playersCountFilter} icon="pi pi-times" onClick={() => setPlayersCountFilter(null)}/>
      </div>
    </div>
  }

  return <>
    <Panel className="data-list-panel" headerTemplate={headerTemplate} pt={{content: {className: "data-list-panel-content gallery-panel-content"}}}>
      <BlockUI blocked={galleryBig.length + gallerySmall.length === 0} template={<DataLoadingIndicator/>} containerClassName="flex-grow-1">
        <div id="galleryContainer" className={"pt-4 overflow-auto d-flex justify-content-center flex-wrap" + (isSmallScreenVertical() ? "" : " ps-5 pe-5")}>
          {galleryBig}
          <hr className="w-100 h-0 border-0"/>
          {gallerySmall}
        </div>
      </BlockUI>
    </Panel>
  </>
}
