import {Badge} from "primereact/badge";
import chroma from "chroma-js";
import {RATINGS} from "../utilities/Constants";

export default function Rating(props) {

  const getRatingColor = (rating) => {
    const colorScale = chroma.scale(['#e06666', '#e2d98b', '#6aa84f']).domain([1, 5]);
    return colorScale(rating);
  }

  const getTooltipContent = (rating) => {
    const floor = Math.floor(rating);
    const ceil = Math.ceil(rating);
    if (floor === ceil) {
      return rating + ": " + RATINGS[rating];
    }
    return floor + ": " + RATINGS[floor] + "<br/>" + ceil + ": " + RATINGS[ceil];
  };

  return <Badge value={props.value} style={{background: getRatingColor(props.value), width: "3rem", borderRadius: "10px"}}
                data-tooltip-id={props.tooltipId} data-tooltip-html={getTooltipContent(props.value)}/>

}
